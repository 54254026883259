<template>
    <el-card class="v_task_list">
        <div class="g-flex"  slot="header">
            <span>账号列表</span>
        </div>
        <el-form :inline="true" size="mini" :model="query">
          <!-- 搜索 -->
          <el-form-item label="账号状态：">
            <el-select v-model="query.status" @change="getList()">
              <el-option label="全部" value="ALL"></el-option>
              <el-option label="审核不通过" value="0"></el-option>
              <el-option label="待审核" value="1"></el-option>
              <el-option label="审核通过" value="2"></el-option>
            </el-select>
          </el-form-item>

        <el-form-item label="平台类型：">
            <el-select v-model="query.platform_id" @change="getList()">
              <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
            </el-select>
          </el-form-item>

            <!-- <el-form-item label="时间：">
                <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                                :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>   -->

              <el-form-item label="收货人手机号:">
               <el-input v-model="query.mobile" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-form-item>  

            <el-form-item label="平台账号:">
               <el-input v-model="query.account" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-form-item>   
            

            <el-form-item>
                <el-select slot="label" v-model="query.search_key" placeholder="查询">
                    <el-option label="用户手机号" value="mobile"></el-option>
                    <el-option label="用户ID" value="user_id"></el-option>
                </el-select>
                <el-row>
                    <el-col :span="12">
                        <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button type="info" @click="resetData">重置</el-button>
                    </el-col>
                     <el-col :span="4" :offset="1">
                        <el-button type="primary" @click="getList()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form-item>

        </el-form>
        <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
            <el-table-column label="用户ID" width="100" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{scope.row.user_id}}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户手机号" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span>{{ scope.row.user.mobile }}</span>
                </template>
            </el-table-column>
             <el-table-column label="平台类型" width="100" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span>{{ scope.row.platform_id | filtersPlatName(platFormList)}}</span>
                </template>
            </el-table-column> 
            <el-table-column label="平台账号">
                <template slot-scope="scope">
                    <span>{{scope.row.account }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收货人姓名" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收货人手机号" width="120" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span>{{ scope.row.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收货人性别" width="100" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                    <span>{{ scope.row.sex | filtersSex }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收货人地址" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span>{{ scope.row.region }} {{ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="100" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span class="g-red" v-if="scope.row.status == 0">审核未通过</span>
                  <span class="g-yellow" v-if="scope.row.status == 1">审核中</span>
                  <span class="g-green" v-if="scope.row.status == 2">审核通过</span>
                </template>
            </el-table-column>

            <el-table-column label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
                </template>
            </el-table-column>

        <el-table-column label="删除" width="210" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status === 1" type="primary" @click="setForm(scope.row, 1)">审核</el-button>
            <el-button size="mini" type="warning" @click="setForm(scope.row, 2)">查看详情</el-button>
            <el-button size="mini" type="danger" @click="deleteAccount(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
        <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
   
    <!--新增和编辑-->
    <el-dialog :close-on-press-escape="false" :title="seeStatus === 1 ? '审核账号': '查看详情'" class="s-task-dialog" top="20px" width="1300px" :close-on-click-modal="false" :visible.sync="form">
                <el-form :model="formData" label-width="160px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 账号信息 -->
                    <el-tab-pane label="用户信息/账号信息" name="1">
                      <el-row :gutter="20">
                        <!-- 用户信息 -->
                        <el-col :span="10">
                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="真实姓名:">
                                <span>{{formData.user.name }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="手机号码:">
                                <span>{{formData.user.mobile }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="状态:">
                                <span>{{ formData.user.real_status | filtersRealStatus }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="性别:">
                                <span>{{ formData.user.sex | filtersSex }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="身份证正面照:">
                                  <el-image  style="width: 150px; height: 100px" fit="cover" :src="formData.user.card_front" :preview-src-list="[formData.user.card_front]"></el-image>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="身份反面照:">
                                  <el-image  style="width: 150px; height: 100px" fit="cover" :src="formData.user.card_back" :preview-src-list="[formData.user.card_back]"></el-image>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="出生年月:">
                                <span>{{ formatDate(formData.user.birthday, 'yyyy-MM-dd') }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="用户昵称:">
                                <span>{{formData.user.nick_name }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="所属省市:">
                                <span>{{formData.user.region }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="详细地址:">
                                <span>{{ formData.user.address }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row v-if="formData.bank">
                            <el-col :span="12">
                              <el-form-item label="银行名称:">
                                <span>{{formData.bank.bank_name }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="开户行:">
                                <span>{{formData.bank.bank_name }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row v-if="formData.bank">
                            <el-col :span="12">
                              <el-form-item label="开户人:">
                                <span>{{formData.bank.name }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="银行卡号:">
                                <span>{{ formData.bank.card_number }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>

                          <el-row v-if="formData.alipay">
                            <el-col :span="12">
                              <el-form-item label="支付宝账号:">
                                <span>{{formData.alipay.name }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="支付宝名字:">
                                <span>{{formData.alipay.card_number }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>

                          <el-row>
                            <el-col :span="12">
                              <el-form-item label="QQ:">
                                <span>{{formData.user.qq }}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="微信:">
                                <span>{{  formData.user.wechat  }}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          </el-col>
                          <!-- 账号信息 -->
                          <el-col :span="12" :offset="1">
                            <el-row>
                              <el-col :span="12">
                                  <el-form-item label="账号名称:">
                                    <span>{{formData.account}}</span>
                                  </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                  <el-form-item label="所属平台:">
                                    <span>{{formData.platform_id | filtersPlatName(platFormList) }}</span>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <el-form-item label="用户昵称:">
                                    <span>{{formData.user.nick_name }}</span>
                                  </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                  <el-form-item label="用户头像:">
                                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.user.head_img" :preview-src-list="[formData.user.head_img]"></el-image>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <el-form-item label="收货人姓名:">
                                    <span>{{formData.name}}</span>
                                  </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                  <el-form-item label="收货人手机:">
                                    <span>{{formData.mobile}}</span>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                              <el-row>
                                <el-col :span="12">
                                  <el-form-item label="收货人地区:">
                                    <span>{{formData.region}}</span>
                                  </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                  <el-form-item label="收货人详细地址:">
                                    <span>{{formData.address}}</span>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                            <!-- 只有淘宝 京东 xinyu_img 需要展示信誉等级和截图 -->
                            <el-row v-if="formData.platform_id == 1 || formData.platform_id == 2">
                              <el-col :span="12">
                                <el-form-item label="信誉等级:">
                                  <span>{{formData.xinyu.title }}</span>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item label="信誉截图:">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.xinyu_img" :preview-src-list="[formData.xinyu_img]"></el-image>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <!--如果是淘宝 如果开通花呗了 credit_pay_img 就要展示花呗截图 -->
                            <el-row v-if="formData.platform_id == 1">
                              <el-col :span="12">
                                <el-form-item label="开通花呗:">
                                  <span>{{ formData.credit_pay | filtersCreditPay }}</span>
                                </el-form-item>
                              </el-col>
                              <!-- 花呗没开通就不要展示花呗截图 -->
                              <el-col :span="12" v-if="formData.credit_pay">
                                <el-form-item label="花呗截图:">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.credit_pay_img" :preview-src-list="[formData.credit_pay_img]"></el-image>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <!-- 如果是京东 如果开通了白条 credit_pay_img 就要展示白条截图 -->
                            <el-row v-if="formData.platform_id == 2">
                              <el-col :span="12">
                                <el-form-item label="开通白条:">
                                  <span>{{ formData.credit_pay | filtersCreditPay }}</span>
                                </el-form-item>
                              </el-col>
                              <!-- 白条没开通就不要展示白条截图 -->
                              <el-col :span="12" v-if="formData.credit_pay">
                                <el-form-item label="白条截图:">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.credit_pay_img" :preview-src-list="[formData.credit_pay_img]"></el-image>
                                </el-form-item>
                              </el-col>
                            </el-row>

                            <!-- 如果是京东 account_img 叫做账户截图 -->
                            <el-row>
                              <el-col :span="12" v-if="formData.platform_id == 2 || formData.platform_id == 3">
                                <el-form-item label="用户名/账户截图:">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="formData.account_img" :preview-src-list="[formData.account_img]"></el-image>
                                </el-form-item>
                              </el-col>
                              <!-- 实名认证/qq/微信截图 real_img 必填的 -->
                              <el-col :span="12" v-if="formData.platform_id != 3">
                                <el-form-item label="实名认证截图:">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.real_img" :preview-src-list="[formData.real_img]"></el-image>
                                </el-form-item>
                              </el-col> 
                              <!-- 实名认证/qq/微信截图 real_img 必填的 -->
                              <el-col :span="12" v-if="formData.platform_id == 3">
                                <el-form-item label="QQ/微信截图:">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.real_img" :preview-src-list="[formData.real_img]"></el-image>
                                </el-form-item>
                              </el-col> 
                            </el-row>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                  </el-tabs>

                  <el-row>
                    <el-col :span="18">
                      <el-form-item label="审核不通过理由:">
                         <el-input placeholder="请输入审核不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="form = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="form = false" v-show="seeStatus === 2">确定</el-button>
            <el-button type="danger"  @click="apiAccountNoPassHandel" v-show="seeStatus === 1">审核不通过</el-button>
            <el-button type="success" v-show="seeStatus === 1" @click="apiAccountPassHandel">审核通过</el-button>
        </div>
    </el-dialog>
   
    </el-card>
    
</template>

<script>
  import { apiGetAccountList,  apiGetShopTypeList, apiAccountPass ,apiAccountNoPass, apiDelAccount } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
    export default {
      name: "accountList",
       async created() {
          await this.apiGetShopTypeListHandel()
          await this.getList()
        },
        data(){
            return {
                auth: {
                  taskpass: this.$global.ApiList.includes(23), //任务审核
                  taskagain: this.$global.ApiList.includes(24), //再次发布任务
                  taskpay: this.$global.ApiList.includes(25), //任务支付
                },
                // 1 审核 2 查看详情
                seeStatus: 1,

                activeName:'1',
                platFormList: [ { id: 'ALL', title: '全部'} ],
                query: {
                    status: 'ALL',
                    platform_id: 'ALL',
                    title:'',
                    date_time: [],
                    search_key: 'mobile', search_val: '',
                    page: 1,
                    limit: 15
                },
                table: {loading: false, total: 0, rows: []},
                form: false,
                title: '开心掌柜',
                // 任务回收时间
                taskTimeList: [
                  { name: '2小时', value: 2 },
                  { name: '4小时', value: 4 },
                  { name: '8小时', value: 8 },
                  { name: '12小时', value: 12 },
                  { name: '24小时', value: 24 },
                  { name: '48小时', value: 48 },
                  { name: '当天24点', value: 0 },
                ],
                formData: {
                  img: [],
                  remark: '',
                  userShop: { account : '' },
                  platform: { title: '' },
                  platformPlay: { title: '' },
                  goods: [],
                  task_type: { title: '' },
                  search_keyword: [],
                  entrance_pic: [],
                  xdsj: { title: '' },
                  huobi: { title: '' },
                  liulan: { title: '' },
                  main_liulan: [{ title: '' }],
                  explain_img: [],
                  xinyu: { title: '' },
                  shop_limit: 0,
                  account_limit: 0,
                  close_time: 0,
                  time_interval: 0,
                  time_val: 0,
                  user: {},
                  bank: false,
                  alipay: false,
                },
            }
        },
        filters: {
          filtersCreditPay(pay) {
            if(pay == 1) return '已开通'
            return '未开通'
          },
          filtersRealStatus(status) {
            if(status == 0) return '审核未通过'
            if(status == 1) return '审核中'
            if(status == 2) return '审核通过'
          },
          filtersPlatName(id, list) {
            let obj = list.find(item => item.id == id)
            if(obj) return obj.title
            return ''
          },
          filtersSex(sex) {
            if(sex == 1) return '男'
            return '女'
          },
          filtersStatus(status) {
            if(status == 0) return '审核未通过'
            if(status == 1) return '审核中'
            if(status == 2) return '审核通过'
          }
        },
        methods: {
          formatDate,

          resetDialog() {
            this.activeName = '1'
            this.form = false
          },

          // 删除账号
          deleteAccount(item) {
            this.$prompt('请输入删除该账号的理由：', '删除账号', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern:  /\S/,
              inputErrorMessage: '理由不能为空'
            }).then(async ({ value }) => {
              this.table.loading = true
              const { success, data } = await apiDelAccount(Object.assign({}, { id: item.id }, { remark: value })).catch(() =>  this.table.loading = false)
              this.table.loading = false
              if(!success) return
              this.$message.success(data.msg)
              this.getList(false)
            }).catch(() => {});
          },

          // 审核通过
          async apiAccountPassHandel() {
            this.table.loading = true
            const { success, data } = await apiAccountPass({ id: this.formData.id }).catch(() => { this.table.loading = false })
            this.table.loading = false
            if(!success) return
            this.$message.success(data.msg)
            this.resetDialog()
            this.getList(false)
            console.log(data)
          },

          // 审核不通过
          async apiAccountNoPassHandel() {
            if(!this.formData.remark) return this.$message.error('请输入审核不通过理由')
            this.table.loading = true
            const { success, data } = await apiAccountNoPass({ id: this.formData.id, remark: this.formData.remark }).catch(() => {this.table.loading = false })
            this.table.loading = false
            if(!success) return
            this.$message.success(data.msg)
            this.resetDialog()
            this.getList(false)
            console.log(data)
          },

          //设置form
          setForm(formData = { id: '' }, seeStatus = 2) {
            console.log(formData)
            this.formData = JSON.parse(JSON.stringify(formData)) 
            this.seeStatus = seeStatus
            this.form = true
          },

          // 获取平台列表
          async apiGetShopTypeListHandel() {
            const { success, data } = await apiGetShopTypeList()
            if(!success) return
            this.platFormList = this.platFormList.concat(data.list)
            console.log('platFormList', this.platFormList)
          },

          //获取列表
          async getList(pageInit = true) {
              if (pageInit) this.query.page = 1
              this.table.loading = true
              const { success, data } = await apiGetAccountList(this.query).catch(() => {this.table.loading = false })
              this.table.loading = false
              if(!success) return
              this.table.rows = data.rows
              this.table.total = data.total
          },

          //每页数量改变
          sizeChange(limit) {
              this.query.limit = limit
              this.getList()
          },
          
          // 点击重置
          resetData() {
            this.query = {
                status: 'ALL',
                platform_id: 'ALL',
                title:'',
                date_time: [],
                search_key: 'mobile', search_val: '',
                page: 1,
                limit: 15
            }
            this.getList()
          },
        }
    }
</script>
<style lang="scss">
  .v_task_list {
    .s-task-dialog {
      .el-form {
        .el-form-item__label {
          font-weight: bold;
        }
        .el-form-item__content {
          color: $grey;
        }
      }
    }
    .s-hr {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background: #e2e3e6;
    }
  }
</style>